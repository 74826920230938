<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <m-card :title="$t('shared.add')">
      <base-form v-model="model" />

      <template #actions>
        <page-footer @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],

  methods: {
    async submit() {
      this.progressing = true
      this.model.password = this.model.password || null
      this.model.passwordConfirm = this.model.passwordConfirm || null
      try {
        this.model = await this.$api.users.create(this.model)
        this.$showSuccess(this.$t('users.userAdded'))
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    }
  }
}
</script>
